[contenteditable] {
  outline: 0px solid transparent;
}

.tiptap {
  max-width: 60rem;
}

.tiptap * {
  max-width: 100%;
}

.tiptap .is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

a {
  color: #68cef8;
}

p {
  font-size: 16px;
}

ul[data-type="taskList"] {
  list-style: none;
}

ul[data-type="taskList"] p {
  margin: 0;
}

ul[data-type="taskList"] li {
  display: flex;
  gap: 0.5rem;
}

table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

table p {
  margin: 0;
}

td,
th {
  border: 2px solid #414141;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}

td > *,
th > * {
  margin-bottom: 0;
}

th {
  background-color: #292929;
  font-weight: bold;
  text-align: left;
}

.selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
