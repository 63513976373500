#video-container {
  position: relative;
  height: fit-content;
  display: flex;
}

#video-container .controls {
  position: absolute;
  bottom: -0.5px;
  width: calc(100% + 0.5px);
  display: inline-flex;
  justify-content: space-around;
  opacity: 0;
  transition: opacity 0.4s;
  flex-direction: column;
  z-index: 10;
}

#video-container:hover .controls {
  opacity: 1;
}

#video-container .controls button:not([disabled]) {
  color: #fff;
  font-weight: bolder;
  border: none;
  cursor: pointer;
}

#progress-bar .MuiSlider-root .MuiSlider-rail:before {
  content: "";
  background: rgba(200, 200, 200, 0.5);
  width: var(--w);
  height: 4px;
  position: absolute;
  border: none;
  backface-visibility: none;
  -webkit-backface-visibility: hidden;
}

#progress-bar .MuiSlider-root:hover .MuiSlider-rail:before {
  content: "";
  width: var(--w);
  height: 5px;
  opacity: initial;
  transition: opacity 0.3s;
  border: none;
  backface-visibility: none;
  -webkit-backface-visibility: hidden;
}

#progress-bar .MuiSlider-root .MuiSlider-rail {
  height: 4px;
  position: absolute;
  opacity: 0.5;
  background: rgba(200, 200, 200, 0.3);
  border: none;
  backface-visibility: none;
  -webkit-backface-visibility: hidden;
}

#progress-bar .MuiSlider-root .MuiSlider-track {
  height: 4px;
  position: absolute;
  opacity: 0.8;
  border: none;
  backface-visibility: none;
  -webkit-backface-visibility: hidden;
}

#progress-bar .MuiSlider-root:hover .MuiSlider-track {
  height: 5px;
  opacity: 1;
}

#progress-bar .MuiSlider-root:hover .MuiSlider-rail {
  height: 5px;
  position: absolute;
  opacity: initial;
  transition: opacity 0.3s;
  opacity: 1;
  border: none;
  backface-visibility: none;
  -webkit-backface-visibility: hidden;
}
